import { Box, useTheme } from '@mui/material';
import React from 'react';
import { FLEX_COL_CENTERED } from '../styles/material-sx/styles';
import { FIXED_DEAD_CENTER } from '../styles/styles';
import CircularWithLabel from './CircularWithLabel';
interface LoaderProps {
    image?: string;
}

const Loader = ({ image }: LoaderProps) => {
    const theme = useTheme();
    return (
        <Box sx={{ ...FIXED_DEAD_CENTER }}>
            <CircularWithLabel
                label={<Box m={2}>{image ? <img src={image} style={{ width: 56, marginTop: 4 }} /> : null}</Box>}
                barcolor={theme.palette.success.main}
                variant="indeterminate"
                size={96}
            ></CircularWithLabel>
        </Box>
    );
};

export default Loader;
