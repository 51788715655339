export const FIXED_DEAD_CENTER = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
} as React.CSSProperties;

export const IN_RELATIVE_DEAD_CENTER = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
} as React.CSSProperties;

export const WHOLE_PAGE = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
} as React.CSSProperties;

export const THUMBNAIL_STYLE = {
    width: 200,
    margin: 10,
    border: '3px #aaa solid',
    padding: 3,
    borderRadius: 10,
    backgroundColor: '#aaa',
};

export const SHADOW = {
    boxShadow: `rgba(0, 0, 0, 0.15) 2.95px 2.95px 8px`,
};

export const BOTTOM_NAV_Z_INDEX = 4000;

export const gradient = (startColor: string, endColor: string) => {
    return { background: `linear-gradient(90deg, ${startColor},${endColor}` };
};

export const DRAWER_WIDTH = 300;
export const APPBAR_HEIGHT = 64;
export const DEFAULT_FONT_SIZE = 14;
export const THIRD_LAYER_COLOR = '#777';

export const RAIL_WIDTH = 200;
export const RAIL_BACKGROUND_COLOR = '#ddd';
