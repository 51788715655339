import { ReactNode } from 'react';
import { create } from 'zustand';

export class AppBreadcrumb {
    constructor(text: ReactNode, route: string) {
        this.text = text;
        this.route = route;
    }

    text: ReactNode;
    route: string;
}

export type RailRoute = {
    text: string;
    route: string;
    icon: ReactNode;
};

export interface NavigationStore {
    isNavbarExpanded: boolean;
    breadcrumbs: (AppBreadcrumb | null)[];
    setIsNavbarExpanded: (value: boolean) => void;
    setBreadcrumbs: (value: (AppBreadcrumb | null)[]) => void;
    selectedDrawerItem: string;
    setSelectedDrawerItem: (value: string) => void;
    railRoutes: RailRoute[];
    setRailRoutes: (value: RailRoute[]) => void;
    selectedRailRoute: string;
    setSelectedRailRoute: (value: string) => void;
}

const useNavigationStore = create<NavigationStore>((set) => ({
    isNavbarExpanded: false,
    breadcrumbs: [],
    setIsNavbarExpanded: (value: boolean) => set({ isNavbarExpanded: value }),
    setBreadcrumbs: (value: (AppBreadcrumb | null)[]) => set({ breadcrumbs: value }),
    selectedDrawerItem: '',
    setSelectedDrawerItem: (value: string) => set({ selectedDrawerItem: value }),
    railRoutes: [],
    setRailRoutes: (value: RailRoute[]) => set({ railRoutes: value }),
    selectedRailRoute: '',
    setSelectedRailRoute: (value: string) => set({ selectedRailRoute: value }),
}));

export default useNavigationStore;
