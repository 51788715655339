import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ScreenClassProvider } from 'react-grid-system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Providers } from '@microsoft/mgt-element';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './services/auth.service';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: 'https://c111bf9331e7bfdf09162fb857cea3cc@o4506514515427328.ingest.us.sentry.io/4507250146541568',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/vitl-api.onrender\.com\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ScreenClassProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <App />
                        </LocalizationProvider>
                    </ScreenClassProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
