import { http } from '../http/http';
import { LoginForm } from '../pages/login/Login';
import { LogLevel } from '@azure/msal-browser';
import * as environment from '../environments.json';
import { User } from 'database';

const ENDPOINT = 'auth';

export interface Tokens {
    accessToken: string;
    refreshToken: string;
}

export const login = async (body: LoginForm): Promise<Tokens> => {
    const { data: tokens } = await http.post(`${ENDPOINT}/login`, body);
    return tokens;
};

export const getMe = async (): Promise<User> => {
    const { data: user } = await http.get(`${ENDPOINT}/me`);
    return user;
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
    auth: {
        clientId: '44c22fd5-fac8-4fab-9ddc-741c2945b08e',
        authority: 'https://login.microsoftonline.com/b4afd718-7a52-4da6-9e9c-b691e5a38b55',
        redirectUri: environment.appUrl,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                // switch (level) {
                //     case LogLevel.Error:
                //         console.error(message);
                //         return;
                //     case LogLevel.Info:
                //         console.info(message);
                //         return;
                //     case LogLevel.Verbose:
                //         console.debug(message);
                //         return;
                //     case LogLevel.Warning:
                //         console.warn(message);
                //         return;
                //     default:
                //         return;
                // }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ['User.Read'],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const impersonate = async (id: number): Promise<Tokens> => {
    const { data: tokens } = await http.post<Tokens>(`${ENDPOINT}/${id}/impersonate`);
    return tokens;
};
