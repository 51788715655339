import React, { ReactNode } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CircularWithLabel = (
    props: CircularProgressProps & {
        label: ReactNode;
        barcolor: string;
        hasFilledRemainder?: boolean;
        variant?: string;
    }
) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} style={{ color: `${props.barcolor}`, zIndex: 2 }} />
            {props.hasFilledRemainder && (
                <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress variant="determinate" {...props} value={100} style={{ color: `#999999`, zIndex: 1 }} />
                </Box>
            )}
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" component="div">
                    {props.label}
                </Typography>
            </Box>
        </Box>
    );
};

export default CircularWithLabel;
