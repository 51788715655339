import { AxiosError } from 'axios';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';

const useNotistack = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showError = (message: string, options?: Partial<{ persist: boolean; autoHideDuration: number }>) => {
        enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
            ...options,
        });
    };

    const showAxiosError = (error: AxiosError) => {
        enqueueSnackbar(error.response?.data.message || error.message, {
            variant: 'error',
            autoHideDuration: 3000,
        });
    };

    const showSuccess = (message: string, options?: Partial<{ persist: boolean; autoHideDuration: number }>) => {
        enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 3000,
            ...options,
        });
    };

    const showWarning = (message: string, options?: Partial<{ persist: boolean; autoHideDuration: number }>) => {
        enqueueSnackbar(message, {
            variant: 'warning',
            autoHideDuration: 3000,
            ...options,
        });
    };

    return {
        showError,
        showAxiosError,
        showSuccess,
        showWarning,
    };
};

export default useNotistack;
