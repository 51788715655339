import { Button, ButtonProps, CircularProgress, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

type SubmitButtonProps = {
    isLoading: boolean;
    children: React.ReactNode;
    vitlVariant?: 'auto' | 'crm';
} & ButtonProps;

export const DefaultSubmitButtonSx = {
    mt: 2,
    borderRadius: 0,
    textTransform: 'none',
    fontWeight: 700,
    py: 2,
    fontSize: 18,
};

const SubmitButton = ({ isLoading, children, ...props }: SubmitButtonProps) => {
    const theme = useTheme();
    const noSxProps = useMemo(() => {
        const { sx, ...rest } = props;
        return rest;
    }, [props]);

    const getVariantStyles = () => {
        if (props.vitlVariant === 'crm') {
            return {
                ...DefaultSubmitButtonSx,
                borderRadius: 0,
                p: 1,
                fontSize: 16,
            };
        }
        return DefaultSubmitButtonSx;
    };
    return (
        <Button
            type="submit"
            disabled={isLoading}
            disableElevation
            sx={{
                ...getVariantStyles(),
                ...(props.sx ? (props.sx as any) : {}),
            }}
            color={isLoading ? 'inherit' : 'success'}
            fullWidth
            variant="contained"
            {...noSxProps}
            endIcon={isLoading ? null : props.endIcon}
            startIcon={isLoading ? null : props.startIcon}
        >
            {isLoading ? <CircularProgress size={24} /> : children}
        </Button>
    );
};

export default SubmitButton;
