import React, { useEffect, useState } from 'react';
import { useScreenClass } from 'react-grid-system';

export const useGridSystem = () => {
    const screenClass = useScreenClass();
    const [xs, setXs] = useState<boolean>(false);
    const [sm, setSm] = useState<boolean>(false);
    const [md, setMd] = useState<boolean>(false);
    const [lg, setLg] = useState<boolean>(false);
    const [xl, setXl] = useState<boolean>(false);
    const [xxl, setXxl] = useState<boolean>(false);
    useEffect(() => {
        let _xs = false;
        let _sm = false;
        let _md = false;
        let _lg = false;
        let _xl = false;
        let _xxl = false;
        if (screenClass === 'xxl') {
            _xxl = true;
        } else if (screenClass === 'xl') {
            _xxl = true;
            _xl = true;
        } else if (screenClass === 'lg') {
            _xxl = true;
            _xl = true;
            _lg = true;
        } else if (screenClass === 'md') {
            _xxl = true;
            _xl = true;
            _lg = true;
            _md = true;
        } else if (screenClass === 'sm') {
            _xxl = true;
            _xl = true;
            _lg = true;
            _md = true;
            _sm = true;
        } else if (screenClass === 'xs') {
            _xxl = true;
            _xl = true;
            _lg = true;
            _md = true;
            _sm = true;
            _xs = true;
        }
        setXs(_xs);
        setSm(_sm);
        setMd(_md);
        setLg(_lg);
        setXl(_xl);
        setXxl(_xxl);
    }, [screenClass]);
    return { screenClass, xs, sm, md, lg, xl, xxl };
};
